import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Helmet } from "react-helmet";
import { FaLink } from "react-icons/fa";
import BackButtonHeader from "../../components/BackButtonHeader";
import "./projects.scss";

function SubMM(): JSX.Element {
  return (
    <div className="project-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Eshed Margalit | Submillimeter Imaging of Human Visual Cortex
        </title>
        <html lang="en" />
      </Helmet>
      <BackButtonHeader title="Submillimeter Imaging of Human Visual Cortex" />

      <div className="project-info">
        <p>
          Recent advances in fMRI technology have allowed insights into the
          functional organization of cortical regions at unprecented resolution.
          In collaboration with the CVN Lab at the University of Minnesota, we
          are examining areas of the brain that preferentially respond to
          different object categories and studying their arrangement in the
          cortical sheet.
        </p>
        <StaticImage
          className="project-image"
          src="../../images/projectImages/submm_representations.png"
          alt="Data shown on the ventral surface of a human brain"
          placeholder="tracedSVG"
          height={250}
          width={250}
        />
      </div>

      <hr />

      <h3>Read the Paper</h3>
      <a
        href="https://www.jneurosci.org/content/early/2020/02/21/JNEUROSCI.2106-19.2020"
        target="_blank"
        rel="noreferrer"
      >
        <div className="project-link">
          <div className="title">
            Margalit, E., Jamison, K. W., Weiner, K. S., Vizioli, L., Zhang, R.
            Y., Kay, K. N., & Grill-Spector, K. (2020). Ultra-high-resolution
            fMRI of human ventral temporal cortex reveals differential
            representation of categories and domains. Journal of Neuroscience,
            40(15), 3008-3024.
          </div>
        </div>
      </a>
    </div>
  );
}

export default SubMM;
